export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"agd-partner-manual-verification"}],"link":[],"style":[],"script":[{"type":"text/javascript","innerHTML":"\n            (function(c,l,a,r,i,t,y){\n              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};\n              t=l.createElement(r);t.async=1;t.src=\"https://www.clarity.ms/tag/\"+i;\n              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);\n            })(window, document, \"clarity\", \"script\", \"nfdya99j8l\");\n          ","body":true},{"type":"text/javascript","innerHTML":"\n            !function(f,b,e,v,n,t,s)\n            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n            n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n            n.queue=[];t=b.createElement(e);t.async=!0;\n            t.src=v;s=b.getElementsByTagName(e)[0];\n            s.parentNode.insertBefore(t,s)}(window, document,'script',\n            'https://connect.facebook.net/en_US/fbevents.js');\n            fbq('init', '1752822918584306');\n            fbq('track', 'PageView');\n          ","body":true}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'